<template>
<section class="brandinfo-wrapper">
    <h1 class="header-title">品牌介绍</h1>

    <!-- form -->
    <el-form class="form-content" ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
        <el-form-item label="上传图片" prop="image">
            <p class="tip">支持png，jpeg，jpg格式，图片像素建议为：690*320px，最多可上传1张；图片大小不能超过3MB。</p>
            <el-upload
                action=""
                :http-request="uploadHandler1"
                :before-upload="beforeUploadHandler"
                :file-list="form.image"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1">
                <div class="el-upload--picture-card" v-if="form.image[0]&&!form.image[0].url">
                    <p  class="upload-custom flex flex-column align-center flex-center">
                        <i class="el-icon-plus el-icon"></i><br/>
                        <span >添加上传图片</span>
                    </p> 
                </div>
                <img v-else :src="form.image[0].url" alt="" class="upload-img">
            </el-upload>
        </el-form-item>
       
        <el-form-item label="品牌介绍" prop="introduction">
            <textarea class="textarea" v-model="form.introduction"></textarea>
        </el-form-item>
        <el-form-item>
            
        </el-form-item>
    </el-form>
    <div class="footer-btn">
        <el-button>取消</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
    </div>
    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</section>
</template>

<script>
let init = false;
function validateImage(rule, value, callback){
    if(value.length==0){
        callback(new Error('请上传logo'))
    }else{
        callback()
    }
}


import utils from '@/utils/utils.js';
export default {
    data(){
        return {
            form:{
               introduction:'',
               image:[{url:''}]
            },
            rules:{
                image:[{required: true, validator:validateImage, trigger: 'change'}],
                introduction:[{required: true, message: '请输入客服微信', trigger: 'blur'}],
            },
            dialogVisible: false,
            dialogImageUrl: ''
        }
    },
    created(){
        this.getBaseInfo();
    },
    methods:{

        async getBaseInfo(){
            const rs = await this.$https.cloud({
                action:'global',
                type:'getBrand'
            });
            console.log(rs);
            if(rs.code!=0) return;
            this.form.introduction = rs.data.data[0].data.brandIntroduction.value;
            let imgList = [{fileid:rs.data.data[0].data.brandImage.value}];
            imgList = await this.$https.getImagePath(imgList);
            console.log(imgList);
            imgList.forEach(item=>{
                item.url = item.download_url
            });
            this.form.image = [imgList[0]];
        },



        //  ==================
        handlePictureCardPreview(file){
            this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        beforeUploadHandler(file){
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
            this.$message.error('上传图片大小不能超过 3MB!');
            }
            return isLt3M;
        },
        handleRemove1(file,fileList){
            this.form.image = [];
        },
        uploadHandler1(file,fileList){
            
            this.upLoadCode(file).then(rs=>{

                this.form.image = [rs];
            });
        },
        async upLoadCode(file){
            const localPath = utils.Img_URL(file.file);
            const rs = await this.$https.uploadImage('setting',file.file);
            return {
                url: localPath,
                fileid: rs,
                uid: file.file.uid
            };
        },
        async saveHandler(){
            if(init){
                this.$message.error('请不要重复提交');
                return;
            }
             this.$refs.form.validate( async valid=>{
                if(!valid) return;
                const rs = await this.$https.cloud({
                    action:'global',
                    type:'setBrand',
                    formData:{
                        image: this.form.image[0].fileid,
                        introduction: this.form.introduction,
                    }
                });
                if(rs.code==0){  
                    init = true;
                    setTimeout(()=>{init=false;},5*1000);
                    this.$message.success(rs.msg);
                }else{

                    this.$message.error(rs.msg);
                }
            });
            
        },
    }
}
</script>

<style lang="scss" scoped>
.brandinfo-wrapper {
    padding: 10px;
    height: 100%;
    position: relative;
    background: #fff;

    .header-title {
        font-size: 16px;
        line-height: 22px;
        padding: 13px 0 12px 0;
        border-bottom: 1px solid #D9D9D9;
    }

    .form-content {
        max-width: 800px;
        margin-top: 40px;

        .textarea {
            padding: 10px;
            line-height: 20px;
            width: 500px;
            height: 300px;
            resize: none;
            border: 1px solid #ccc;
        }
    }

    .upload-img {
        width: 148px;
        height: 148px;
        border-radius: 6px;
    }
}
</style>